<template>
    <div class='demo-app'>

    <!--
        <div class='demo-app-sidebar'>
            <div class='demo-app-sidebar-section'>
                <h2>Instructions</h2>
                <ul>
                    <li>Select dates and you will be prompted to create a new event</li>
                    <li>Drag, drop, and resize events</li>
                    <li>Click an event to delete it</li>
                </ul>
            </div>
            <div class='demo-app-sidebar-section'>
                <label>
                    <input
                    type='checkbox'
                    :checked='state.calendarOptions.weekends'
                    @change="handleWeekendsToggle()"
                    />
                    toggle weekends
                </label>
            </div>
            <div class='demo-app-sidebar-section'>
                <h2>All Events ({{ state.currentEvents.length }})</h2>
                <ul>
                    <li v-for='event in state.currentEvents' :key='event.id'>
                        <b>{{ event.startStr }}</b>
                        <i>{{ event.title }}</i>
                    </li>
                </ul>
            </div>
        </div>
    -->



        <div class='demo-app-main'>
            <div>
                <div class='demo-app-main-section'>
                    <label>
                        <input
                        type='checkbox'
                        :checked='state.calendarOptions.weekends'
                        @change="handleWeekendsToggle()"
                        />
                        toggle weekends
                    </label>
                </div>
            </div>
            <div>
                <FullCalendar
                    class='demo-app-calendar'
                    :options='state.calendarOptions'
                >


                    <template v-slot:eventContent='arg'>
                        <b>{{ arg.timeText }}</b>
                        <i>{{ arg.event.title }}</i>
                    </template>


                </FullCalendar>
            </div>
        </div>



        <MyModal
            v-bind:idProps="state.modal.show"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ state.modal.header }}
            </template>

            <template v-slot:body>
                <div>

                    <p>
                        {{ useEvents.state.calendar.clickInfo }}
                    </p>

                    <ul>
                        <li v-for='(info, index) in useEvents.state.calendar.clickInfo' :key="info">
                            [ {{ index }} ] : {{ info }}
                        </li>
                    </ul>

                </div>
            </template>
        </MyModal>


        <MyModal
            v-bind:idProps="state.modal.edit"
        >

            <template v-slot:header>
                {{ state.modal.header }}
            </template>

            <template v-slot:body>
                <ExceptionMessage
                    v-bind:exception="useEvents.useErrors.state.exception"
                    v-bind:exceptionMessage="useEvents.useErrors.state.exceptionMessage"
                ></ExceptionMessage>

<!--
                <div class="form-floating mb-3">
                    {{ useEvents.state.calendar.selectInfo }}
                    <ul>
                        <li v-for='info in useEvents.state.calendar.selectInfo' :key="info">
                            {{ info }}
                        </li>
                    </ul>
                </div>
-->

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="title"
                        v-bind:class="formUtilsComp.isFieldEmpty(useEvents.state.form.body.title) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="title"
                        v-model="useEvents.state.form.body.title"
                    >
                    <label for="title">Title</label>
                    <div v-if="formUtilsComp.isFieldEmpty(useEvents.state.form.body.title)" class="text-info text-sm">Please fill up with your title</div>
                    <ValidationErrors v-bind:errors="useEvents.useErrors.state.errors.title"></ValidationErrors>
                </div>

            <!--
                <div class="form-floating mb-3">
                    <div :style="{background: color}">
                        <ColorPicker
                            theme="light"
                            :color="color"
                            :sucker-hide="false"
                            :sucker-canvas="suckerCanvas"
                            :sucker-area="suckerArea"
                            @changeColor="changeColor"
                            @openSucker="openSucker"
                        />
                    </div>
                </div>
            -->

                <button
                    @click="saveResource()"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                >Save</button>
                <button
                    @click="deleteResource(useEvents.state.form.body)"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-danger"
                >Delete</button>
            </template>
        </MyModal>




    </div>
</template>


<script>

// import Vue.js framework functions
import { reactive } from 'vue'
import { onMounted } from 'vue'
//import { computed } from 'vue'

// import external functionalities
import { Modal } from 'bootstrap';

// import application's components
import MyModal from '@/components/MyModal.vue'

//import { useStore } from 'vuex'

import FullCalendar from '@fullcalendar/vue3'
import DayGridPlugin from '@fullcalendar/daygrid'
import TimeGridPlugin from '@fullcalendar/timegrid'
import InteractionPlugin from '@fullcalendar/interaction'
import ListPlugin from '@fullcalendar/list'

// Import color picker from :
// https://github.com/anish2690/vue-color-kit
//import { ColorPicker } from 'vue-color-kit'
//import 'vue-color-kit/dist/vue-color-kit.css'


//import { INITIAL_EVENTS } from '@/utils/event-utils.ts'
//import { createEventId } from '@/utils/event-utils.ts'


// import composables files
//import useObjectsUtils from '@/composables/useObjectsUtils.js'
import useFormUtilsComp from '@/composables/useFormUtilsComp.js'
import useEventsComp from '@/composables/useEventsComp.js'

// import informations and errors messages components
// import AltertMessage from '@/components/messages/altertMessage.vue'
import ExceptionMessage from '@/components/messages/exceptionMessage.vue'
import ValidationErrors from '@/components/messages/validationErrors.vue'

export default {

    name: 'calendar',

    components: {
        MyModal,
        ExceptionMessage,
        ValidationErrors,
        FullCalendar,
        //ColorPicker,
    },

    setup() {

        //const objUtils = useObjectsUtils()
        const useEvents = useEventsComp()
        const formUtilsComp = useFormUtilsComp()

        onMounted(() => {
            state.modal.componentEdit = new Modal(document.getElementById(state.modal.edit))
            state.modal.componentShow = new Modal(document.getElementById(state.modal.show))
        });


        const state = reactive({
            id: 0,
            selectedEvent: null,
            modal: {
                edit: 'editModal',
                componentEdit: null,
                show: 'showModal',
                componentShow: null,
                header: '',
                action: '',
            },
            calendarOptions: {
                plugins: [
                    DayGridPlugin,
                    TimeGridPlugin,
                    InteractionPlugin,
                    ListPlugin
                ],
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                },
                locale: 'fr',
                initialView: 'dayGridMonth',
                //initialView: 'timeGridWeek',
                //events: [],
                events: getAllEvents,
                //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
                editable: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                weekends: true,

                // Callbacks
                select: handleDateSelect,
                eventClick: handleEventClick,
                dateClick: handleDateClick,

                // Callbacks for Event Model
                eventAdd: handleEventAdd,
                eventChange: handleEventChange,
                //eventRemove: handleEventRemove,
                eventsSet: handleEventsSet,

            },
            currentEvents: [],
            currentResources: []
        })


        /**
         * [getAllEvents description]
         *
         * @param  {[type]} fetchInfo                     [description]
         * @param  {[type]} successCallback               [description]
         * @param  {[type]} failureCallback               [description]
         * @return {[type]}                 [description]
         */
        function getAllEvents( fetchInfo, successCallback, failureCallback ) {

            console.log('getAllEvents')

            useEvents.getEventsFromDateInterval({
                start: fetchInfo.startStr,
                end: fetchInfo.endStr
            })
            .then(() => {
                successCallback(useEvents.state.form.resources)
            })
            .catch((err) => {
                failureCallback(err)
            })
        }


        /**
         * [handleWeekendsToggle description]
         *
         * @return {[type]} [description]
         */
        function handleWeekendsToggle() {
            state.calendarOptions.weekends = !state.calendarOptions.weekends // update a property
        }

        /**
         * [handleDateClick description]
         *
         * @param  {[type]} info               [description]
         * @return {[type]}      [description]
         */
        function handleDateClick(info) {
            //alert('Clicked on: ' + info.dateStr);
            console.log('handleDateClick')
            console.log('Clicked on: ' + info.dateStr)

            //alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
            //alert('Current view: ' + info.view.type);
            // change the day's background color just for fun
            //info.dayEl.style.backgroundColor = 'red';
        }


        /**
         * Function called by click in a date
         *
         * @param  {[type]} selectInfo               [description]
         * @return {[type]}            [description]
         */
        function handleDateSelect(selectInfo) {

            console.log('handleDateSelect')
            console.log(selectInfo)

            // Define modal attributes
            state.modal.header = 'Create new event'
            state.modal.action = 'create'

            // Copy pointer to selectInfo
            useEvents.state.calendar.selectInfo = selectInfo

            //state.id = 0

            // Open Modal component
            state.modal.componentEdit.toggle()

            // Erase error messages
            useEvents.useErrors.useResetErrors()

            // Flush user's attributes
            useEvents.flushAttributes()
            useEvents.initNewEventAttributes(selectInfo)
        }


        /**
         * [handleEventClick description]
         *
         * @param  {Object} clickInfo [FullCallendar Event Object]
         * @return {void} [no data returned]
         */
        function handleEventClick(clickInfo) {

            console.log('handleEventClick')
            console.log('clickInfo')
            console.log(clickInfo.event)
/*
            console.log('id : ')
            console.log(clickInfo.event.id)
            console.log('title : ')
            console.log(clickInfo.event.title)
*/
            console.log('start : ')
            console.log(clickInfo.event.start)
            console.log('end : ')
            console.log(clickInfo.event.end)
            console.log('allDay : ')
            console.log(clickInfo.event.allDay)

            // Define modal attributes
            state.modal.header = 'Edit event'
            state.modal.action = 'edit'

            // Copy pointer to clickInfo
            useEvents.state.calendar.clickInfo = clickInfo


            //state.selectedEvent = clickInfo.event.getEventById(clickInfo.event.id)

            //clickInfo.backgroundColor = 'green'

            // Open Modal component
            state.modal.componentEdit.toggle()

            // Erase error messages
            useEvents.useErrors.useResetErrors()

            useEvents.flushAttributes()
            useEvents.copyEventAttributes(clickInfo.event)

            // Clone user's attributes
            //useEvents.state.form.body = objUtils.cloneObject(clickInfo.event)
/*
            if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
                useEvents.deleteResource(clickInfo.event.id)
                    .then(() => {
                        clickInfo.event.remove()
                    })

            }
*/
        }

        function handleEventChange(changeInfo) {
            console.log('handleEventChange')

            console.log('event :')
            console.log(changeInfo.event)
            console.log('oldEvent :')
            console.log(changeInfo.oldEvent)
            console.log('revert :')
            console.log(changeInfo.revert)

/*
            if (confirm(`Do you want to get the event back '${clickInfo.event.title}'`)) {
                useEvents.deleteResource(clickInfo.event.id)
                    .then(() => {
                        clickInfo.event.remove()
                    })

            }
*/
        }


        /**
         * [saveResource description]
         *
         * @return {[type]} [description]
         */
        function saveResource() {

            // Copy data to save in DB
            //useEvents.state.form.body.start = useEvents.state.calendar.selectInfo.startStr
            //useEvents.state.form.body.end = useEvents.state.calendar.selectInfo.endStr
            //useEvents.state.form.body.allDay = useEvents.state.calendar.selectInfo.allDay

            if (useEvents.state.form.body.id == null) {

                console.log('store event in backend database')

                useEvents.storeResource()
                .then((resource) => {

                    let title = useEvents.state.form.body.title
                    let calendarApi = useEvents.state.calendar.selectInfo.view.calendar
                    calendarApi.unselect() // clear date selection
                    if (title) {
                        calendarApi.addEvent({
                            id: resource.id,
                            title,
                            start: resource.start,
                            end: resource.end,
                            allDay: resource.allDay,
                            backgroundColor: resource.backgroundColor,
                        })
                    }

                    // Close Modal component
                    state.modal.componentEdit.toggle()
                })
                .catch((error) => {
                    //formUtilsComp.useSetErrors(error.response.data)
                    useEvents.useErrors.useSetErrors(error.response.data)
                })
            } else {

                console.log('update event in backend database')
                console.log('event ID : ' + useEvents.state.form.body.id)

                useEvents.storeResource()
                .then((resource) => {

                    useEvents.state.calendar.clickInfo.event.setExtendedProp( 'title', resource.title )

                    // Close Modal component
                    state.modal.componentEdit.toggle()
                })
                .catch((error) => {
                    //formUtilsComp.useSetErrors(error.response.data)
                    useEvents.useErrors.useSetErrors(error.response.data)
                })

                //useEvents.state.form.body.id = useEvents.state.calendar.selectInfo.id


                //

            }
        }

        /**
         * Delete resource
         *
         * @param  {[type]} resource               [description]
         * @return {[type]}          [description]
         */
        function deleteResource(resource) {

            if (confirm(`Are you sure you want to delete the event '${resource.title}'`)) {
                useEvents.deleteResource(resource.id)
                .then(() => {
                    useEvents.state.calendar.clickInfo.event.remove()

                    // Close Modal component
                    state.modal.componentEdit.toggle()
                })
            }
        }

        /**
         * [handleEventAdd description]
         *
         * @param  {[type]} addInfo               [description]
         * @return {[type]}         [description]
         */
        function handleEventAdd(addInfo) {
            console.log('handleEventAdd')
            console.log(addInfo)
        }

        /**
         * [handleEventsSet description]
         *
         * @param  {[type]} events               [description]
         * @return {[type]}        [description]
         */
        function handleEventsSet(events) {

            console.log('handleEventsSet')

            //console.log(events)

            state.currentEvents = events
        }

        /**
         *
         */
        return {
            state,
            useEvents,
            formUtilsComp,
            handleWeekendsToggle,
            handleDateSelect,
            handleEventClick,
            handleEventsSet,
            saveResource,
            deleteResource
        }

    }
}

</script>

<style>
    @import '../../assets/css/calendar.css';
</style>
