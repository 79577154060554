/*
|--------------------------------------------------------------------------
| Users management features and utilities composable
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods
| used in the different views or components for the Users management
| of the application.
*/

// Import Vue.js Framework functions
import { reactive } from 'vue'
//import { computed } from 'vue'

import { useStore } from 'vuex'

// import own utility function library
import useCrudComp from './useCrudComp.js'
import useErrorsComp from '@/composables/useErrorsComp.js'


export default function useEventsComp() {

    // define variable with the needed utils functions
    const useCrud = useCrudComp()
    const useErrors = useErrorsComp()

    const store = useStore()

    //const eventGuid = 0
    //const todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today


    // define the reactive variables
    const state = reactive({

        calendar: {
            selectInfo: null,
            clickInfo: null,
        },

        form: {
            body: {
                id: null,
                title: '',
                allDay: '',
                start: '',
                end: '',
                backgroundColor: '',
            },
            status: null,
            resources: null,
            loading: true,
            response: {
                message: null,
                alert: null,
            }
        },


    })


    // Flush all user's attributes
    function flushAttributes() {
        state.form.body.id = null
        state.form.body.title = ''
        state.form.body.allDay = ''
        state.form.body.start = ''
        state.form.body.end = ''
        state.form.body.backgroundColor = ''
    }

    function initNewEventAttributes(selectInfo) {
        state.form.body.id = null
        state.form.body.title = ''
        state.form.body.allDay = selectInfo.allDay
        state.form.body.start = selectInfo.start
        state.form.body.end = selectInfo.end
        state.form.body.backgroundColor = 'green'
    }

    function copyEventAttributes(event) {
        state.form.body.id = event.id
        state.form.body.title = event.title
        state.form.body.allDay = event.allDay
        state.form.body.start = event.start
        state.form.body.end = event.end
        state.form.body.backgroundColor = 'grey'
    }

    // Get resources from backend
    function getEvents() {

        state.form.loading = true

        return useCrud.getAllResources({
            url: 'events'
        })
        .then((res) => {
            //state.form.resources = res.data.data
            //store.dispatch('events/setAllEvents', res.data.data)
            state.form.resources = setDataArray(res.data.data)
            state.form.loading = false
            state.form.status = 'success'
        });
    }


    // Get resources from backend
    function getEventsFromDateInterval(interval) {

        state.form.loading = true

        return useCrud.getResourcesFromDateInterval({
            url: 'events/fromInterval',
            resource: interval
        })
        .then((res) => {
            //state.form.resources = res.data.data
            //store.dispatch('events/setAllEvents', res.data.data)
            state.form.resources = setDataArray(res.data.data)
            state.form.loading = false
            state.form.status = 'success'
        });
    }


    // Store new created resource to backend
    function storeResource() {

        useErrors.useResetErrors()
        state.form.status = 'saving'

        console.log('body to store')
        console.log(state.form.body)


        return useCrud.storeResource({
            url: 'events',
            resource: state.form.body
        })
        .then((res) => {
            state.form.resources.push(res.data.resource)

            state.form.status = 'success'

            return res.data.resource
        })
    }


    function setDataArray(data) {

        //console.log(data)
        let length = data.length
        var eventsToShow = data

        for (var i = 0; i < length; i++) {

            if ( data[i].allDay == 1 ) {
                let date = new Date(data[i].start)
                let start = date.toISOString().replace(/T.*$/, '')
                eventsToShow[i].start = start
            }
            else {
                eventsToShow[i].start = data[i].start
            }
        }

        return eventsToShow
    }

    // Update modified resource in backend
    function updateResource() {

        useErrors.useResetErrors()
        state.form.status = 'updating'

        let id = state.form.body.id

        return useCrud.updateResource({
            url: 'events/' + id,
            resource: state.form.body
        })
        .then((res) => {
            var index = state.form.resources.findIndex(x => x.id === res.data.resource.id)

            // if resource not existing in array
            if (index < 0) {
                // add resource to array
                state.form.resources.push(res.resource)
            } else {
                // update resource in array
                state.form.resources.splice(index, 1, res.data.resource)
            }

            state.form.status = 'success'
            state.form.response.message = res.data.message

            return res.data.resource

        })

    }

    // Delete resource in backend
    function deleteResource(id) {

        return useCrud.deleteResource({
            url: 'events/' + id,
            resource: id
        })
        .then(() => {
            var index = state.form.resources.findIndex(x => x.id === id)
            state.form.resources.splice(index, 1)

            state.form.status = 'success'
        })

    }



    return {
        state,
        store,
        flushAttributes,
        initNewEventAttributes,
        copyEventAttributes,
        useErrors,
        getEvents,
        getEventsFromDateInterval,
        storeResource,
        updateResource,
        deleteResource
    }



}
